.contact-wrap {
    /* background: #e8edf0; */
    height: 100%;
}

#contactinfo #map {
    height: 100%;
}

#contactinfo iframe {
    width: 100%;
    height: 100%;
}

#contactinfo {
    background-color: #fff;
    padding-top: 90px;
    padding-bottom: 70px;
    overflow: hidden !important;
    float: none !important;
}

#contactinfo .form-label {
    color: #fff;
}

#contactinfo .form-control {
    font-size: 1.5rem;
    border-radius: 0;
}

#contactinfo .btn {
    border-radius: 0;
    font-size: 1.7rem;
    text-transform: uppercase;
    padding: 1rem 2rem;
    background-color: var(--primary-bg-color);
    border: 0;
    margin: 1rem;
}
