.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  height: 60px;
  background-color: var(--header-bg-color);
}

.menu-icon {
  display: none;
}

.menu-icon svg rect {
  fill: #ffffff;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
}

.navbar ul li {
  cursor: pointer;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-text-color);
  text-decoration: none;
}

.nav-elements ul a.active {
  color: var(--primary-text-active-color);
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-text-active-color);
}

.navbar .logo {
  width: 8rem;
}
@media (max-width: 768px) {
  .navbar .logo {
    width: 9rem;
  }
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 1060px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: var(--header-bg-color);
    width: 0px;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
    z-index: 999;
    background-color: black;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin: 2rem 3rem;
  }
}
