body {
  background: var(--primary-bg-color);
}

.section-title {
  padding-top: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}

.hero-banner .banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hero-banner .banner-text {
  text-align: center;
}

.hero-banner .banner-text .responsive-headline {
  text-transform: uppercase;
  color: #fff;
  font-size: 6rem;
  line-height: 6rem;
}

.hero-banner .banner-text h2 {
  color: #fff;
}

.hero-banner .banner-text h3 span,
.hero-banner .banner-text h3 a {
  color: rgb(255, 255, 255);
}

.hero-banner .banner-text hr {
  width: 60%;
  margin: 18px auto 24px auto;
  border-color: #2f2d2e;
  border-color: rgba(150, 150, 150, 0.1);
}

.modal-content {
  background-color: transparent !important;
  border: none;
}

.modal-dialog {
  max-width: fit-content !important;
}

.modal-content .modal-header {
  border: none;
}

.modal-content .modal-header .btn-close {
  background-color: #ffffff;
}

.modal.show .modal-dialog {
  margin: 10rem;
}

/* ------------------------------------------------------------------ */
/* c. About Section
/* ------------------------------------------------------------------ */

#about {
  background: var(--primary-bg-color);
  padding-top: 120px;
  padding-bottom: 70px;
  overflow: hidden;
}

#about .left-section {
  border-right: 1px solid #ccc;
}

#about a,
#about a:visited {
  color: #fff;
}

#about a:hover,
#about a:focus {
  color: #0762f9;
}

#about h2 {
  font: 22px/30px "opensans-bold", sans-serif;
  color: #fff;
  margin-bottom: 12px;
}

#about p {
  line-height: 30px;
  color: #ccc;
}

#about .contact-details {
  width: 41.66667%;
}

#about .download {
  width: 58.33333%;
  padding-top: 6px;
}

#about .main-col {
  padding-right: 5%;
}

#about .download .button {
  margin-top: 6px;
  background: #444;
}

#about .download .button:hover {
  background: #fff;
  color: #2b2b2b;
}

#about .download .button i {
  margin-right: 15px;
  font-size: 20px;
}

#about .profile-pic {
  position: relative;
  width: 155px;
  height: 155px;
  border-radius: 100%;
  border: 2px solid white;
}

/* ------------------------------------------------------------------ */
/* d. Resume Section
/* ------------------------------------------------------------------ */

.card-slider {
  background: #fff;
  padding: 90px 90px 70px;
  /* padding-bottom: 70px; */
  overflow: hidden;
}

.card-slider .slick-prev:before,
.card-slider .slick-next:before {
  color: #000000;
}

.card-slider .row {
  justify-content: center;
}

.card-slider .client-logo {
  padding: 3rem;
  width: 26rem;
  /* aspect-ratio: 1 / 1; */
  object-fit: cover;
  transition: all .3s ease;
}

.card-slider .client-logo:hover {
  transform: scale(1.2);
}

.CommonListItem,
.work {
  margin-bottom: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
}

#testimonial {
  background: url("../images/testimonials-bg.jpg");
  background-size: cover;
  padding: 90px;
}

#testimonial .section-title {
  color: #fff;
}

#testimonial .slick-dots li.slick-active button:before {
  color: #ffffff;
}

.testimonial blockquote {
  margin: 10px 10px 0;
  background: #fff;
  padding: 60px;
  position: relative;
  border: none;
  border-radius: 8px;
  font-size: 1.25em;
  color: #1e528e;
}

.testimonial blockquote:before,
.testimonial blockquote:after {
  content: "\201C";
  position: absolute;
  font-size: 80px;
  padding: 10px;
  line-height: 1;
  color: #1e528e;
}

.testimonial blockquote:before {
  top: 0;
  left: 10px;
}

.testimonial blockquote:after {
  content: "\201D";
  right: 10px;
  bottom: -.5em;
}

.testimonial div {
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
  margin: 0 0 0 60px;
  margin-bottom: 10px;
}

.testimonial cite {
  padding-left: 20px;
  font-size: 1.25em;
  color: #f7f7f7;
}

/* ------------------------------------------------------------------ */
/* i. Footer
/* ------------------------------------------------------------------ */
#footer {
  background-color: var(--header-bg-color);
  padding-bottom: 70px;
}

footer {
  padding-top: 48px;
  color: var(--primary-text-color);
  font-size: 14px;
  /* text-align: center; */
  position: relative;
}

footer a,
footer a:visited {
  color: var(--primary-text-color);
}

footer a:hover,
footer a:focus {
  color: var(--primary-text-active-color);
}

footer .mikeySocial {
  justify-content: center;
  margin: 18px 0 30px 0;
  padding: 0;
  font-size: 100px;
}

footer .mikeySocial li {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #f06000;

  padding: 20px !important;
}

footer .mikeySocial li:first-child {
  margin-left: 0;
}

/* Go To Top Button */
#go-top {
  position: absolute;
  top: -24px;
  left: 50%;
  margin-left: -30px;
}

#go-top a {
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #525252;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  color: #fff;
  font-size: 21px;
  line-height: 60px;
  border-radius: 100%;
}

#go-top a:hover {
  background-color: #0f9095;
}

.social .btn:first-child {
  margin-right: 30px;
}

.social .btn {
  padding-left: 30px;
  padding-right: 30px;
}

.social .btn i {
  margin-right: 15px;
}

.project-btn {
  background-color: #1abc9c !important;
}

.github-btn {
  background-color: #34495e !important;
}

.hero-banner {
  background: url("../images/lawyer-hero-banner.jpg");
  height: 600px;
  background-size: cover;
  opacity: 0.99;
  text-align: center;
  background-position: right;
}

.social-icons {
  margin-top: 40px;
}

ul.qualifications li {
  list-style: square;
}

#contactinfo .address .contact-icon {
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  #about .left-section {
    border: 0;
  }
}

@media screen and (max-width: 500px) {
  #about {
    padding: 50px 0;
  }

  .card-slider {
    padding: 50px;
  }

  #testimonial {
    padding: 50px;
  }

  #testimonial .testimonial blockquote {
    padding: 30px;
    margin: 0 1rem;
    font-size: 1em;
    line-height: 2rem;
  }

  .testimonial blockquote:before,
  .testimonial blockquote:after {
    font-size: 50px;
  }

  .modal.show .modal-dialog {
    margin: 5rem;
  }
}